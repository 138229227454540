import React from 'react';
import { Nav } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge, faFlask, faUserCircle, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../Session';

const Navigation = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser => 
                authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNoneAuth />
            }
        </AuthUserContext.Consumer>
    </div>
);

const NavigationAuth = ({ authUser }) => (
    <Nav className="mrx-auto" navbar>  
      <Nav.Item className="d-flex align-items-center" style={{ marginRight: 30 }}>
        <Link to={ROUTES.DASHBOARD} className="navbarLink"><FontAwesomeIcon icon={faThLarge} size="lg" /> Dashboard</Link>
      </Nav.Item>
  
      <Nav.Item className="d-flex align-items-center" style={{ marginRight: 30 }}>
        <Link to={ROUTES.SEARCH_30_DAYS} className="navbarLink"><FontAwesomeIcon icon={faSearchengin} size="lg" /> Search 30-Days</Link>
      </Nav.Item>

      <Nav.Item className="d-flex align-items-center" style={{ marginRight: 30 }}>
        <Link to={ROUTES.LABS} className="navbarLink"><FontAwesomeIcon icon={faFlask} size="lg" /> Twitter Labs</Link>
      </Nav.Item>
      
      <Nav.Item className="d-flex align-items-center" style={{ marginRight: 30 }}>
        <Link to={ROUTES.ACCOUNT} className="navbarLink"><FontAwesomeIcon icon={faUserCircle} size="lg" /> Account</Link>
      </Nav.Item>

      {authUser.isAdmin && (
        <Nav.Item className="d-flex align-items-center" style={{ marginRight: 30 }}>
            <Link to={ROUTES.ADMIN} className="navbarLink"><FontAwesomeIcon icon={faUsersCog} size="lg" /> Admin</Link>
        </Nav.Item>  
      )}          
    </Nav>                  
  );
  
  const NavigationNoneAuth = ({ authUser }) => (
    <Nav className="mrx-auto" navbar>  
      
    </Nav>                  
  );

export default Navigation;