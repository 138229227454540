import React from 'react';

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Button variant="outline-danger" size="sm" onClick={firebase.doSignOut}>
    <FontAwesomeIcon icon={faPowerOff} /> Sign Out
  </Button>
);

export default withFirebase(SignOutButton);