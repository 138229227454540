//
//
// WARNING: Do not store any secrets (such as private API keys) in your React app 
//          as they would be exposed in the JS code visible to the client!
//
//

import { css } from 'glamor';

export const APP_URL = window.location.origin;

export const API_URL = (APP_URL === 'https://dev.twtn.me' || APP_URL === 'http://localhost:8000') ? 'https://devapi.twtn.me' : 'https://api.twtn.me';

// *** Admin Twitonomy users (Twitter Ids) ***
// !!!Warning!!! To access the Firebase Realtime Database as Admin, 
// the admin user must have the child isAdmin = true in the "users-read-only-data" table
// (as per the security rules config at https://console.firebase.google.com/project/twtnmesearch/database/twtnmesearch/rules)
export const ADMIN_USERS = ["24873286"];

export const TOAST_STYLES = {
    className: css({
      background: '#fdffd7'
    }),
    bodyClassName: css({
      color: '#5b5f63'
    }),
    progressClassName: css({
      background: '#1968bb'
    })
};