import React, { Component } from 'react';
import { compose } from 'recompose';

import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import * as CONFIG from '../../constants/config';

import axios from 'axios';


class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingUsers: false,
      errorUsers: null,
      users: [],
      loadingBearerToken: false,
      errorBearerToken: null,
      bearerTokenInApp: null,
      bearerTokenGeneratedByTwitter: null
    };
  }

  componentDidMount() {

    // Create Axios CancelToken to allow requests to be cancelled
    this.cancelTokenSource = axios.CancelToken.source();
    
    this.setState({ loadingUsers: true });
    this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = usersObject != null ? Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key
      })) : [];

      this.setState({
        loadingUsers: false,
        errorUsers: null,
        users: usersList
      });
    }, error => {
      this.setState({
        loadingUsers: false,
        errorUsers: "Database error [" + error.message + "]",
        users: []
      });
    });

    this.setState({ loadingBearerToken: true });
    this.props.firebase.getAuthIdToken()
    .then(idToken => {
      // Firebase Auth Token retrieved
      //console.log('autht='+idToken);
      // Call the Twitonomy API
      const axiosHeaders = {
        'Authorization': 'Bearer '+idToken
      };
      const axiosCallParams = {
        type: 'get-bearer-token',
      };
      const axiosValidateStatus = function (status) {
        return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
      }
      axios.get(CONFIG.API_URL + '/admin', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus })
      .then(response => {
        //console.log(response);
        if (response.status === 200 && response.data.data.generated_by_twitter) {
          // API returned successful response
          this.setState({ loadingBearerToken: false, errorBearerToken: null, bearerTokenInApp: response.data.data.in_app_config, bearerTokenGeneratedByTwitter: response.data.data.generated_by_twitter });
        } else {
          // API returned an error
          let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
          this.setState({ loadingBearerToken: false, errorBearerToken: errorMessage, bearerTokenInApp: null, bearerTokenGeneratedByTwitter: null });
        }          
      })
      .catch(error => {
        // Axios error
        console.log(JSON.stringify(error));
        let errorMessage = error.message ? error.message : 'An error occurred';
        this.setState({ loadingBearerToken: false, errorBearerToken: errorMessage, bearerTokenInApp: null, bearerTokenGeneratedByTwitter: null });
      });
    })
    .catch(error => {
      // Error getting Firebase Auth Token
      console.log(error);
      this.setState({ loadingBearerToken: false, errorBearerToken: "Authentication error", bearerTokenInApp: null, bearerTokenGeneratedByTwitter: null });
    });

  }

  componentWillUnmount() {
    //Detach Firebase Database listener (if used on() for live updates instead of once() for a single call)
    //this.props.firebase.users().off();

    // Cancel Axios requests
    this.cancelTokenSource.cancel('API calls cancelled');
  }

  render() {
    const { loadingUsers, errorUsers, users, loadingBearerToken, errorBearerToken, bearerTokenInApp, bearerTokenGeneratedByTwitter } = this.state;

    return (
      <div>
        <Alert key='admin-alert' variant='warning'>This page is only accessible to Admin users</Alert>
        <h1>Admin</h1>

        <h2>Users</h2>
        {loadingUsers ? <Spinner animation="grow" variant="info" size="sm" /> : (errorUsers ? <Alert key='alert-error-users' variant='danger'>{errorUsers}</Alert> : <UserList users={users} />)}

        <h2>Twitter Bearer Token</h2>
        <h3>In-App</h3>
        <div style={{marginBottom: 10}}>{loadingBearerToken ? <Spinner animation="grow" variant="info" size="sm" /> : <code>{bearerTokenInApp}</code>}</div>
        <h3>Live (generated by Twitter)</h3>
        <div style={{marginBottom: 10}}>{loadingBearerToken ? <Spinner animation="grow" variant="info" size="sm" /> : <code>{bearerTokenGeneratedByTwitter}</code>}</div>
        {errorBearerToken ? <Alert key='alert-error-bearer-token' variant='danger'>{errorBearerToken}</Alert> : ""}

        <h2>Twitonomy API</h2>
        <p>{CONFIG.API_URL}</p>
        
      </div>
    );
  }
}

const UserList = ({ users }) => (
  <div>
    {users.length > 0 ? 
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Firebase Id</th>
            <th>Username</th>
            <th>Twitter Id</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
              <tr key={user.uid}>
                <td>{index+1}</td>
                <td>{user.uid}</td>
                <td>{user.username}</td>
                <td>{user.userId}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      :
      <strong>No users</strong>
    }
  </div>
);

const condition = authUser =>
  authUser && !!authUser.isAdmin;

export default compose(
    withAuthorization(condition),
    withFirebase,
  )(AdminPage);