import React, { Component } from 'react';

import * as CONFIG from '../../constants/config';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthUserContext, withAuthorization } from '../Session';

class DashboardPage extends Component {  

  static contextType = AuthUserContext;

  componentDidMount() {

    const authenticatedUser = this.context;
    toast("Welcome @" + authenticatedUser.username + " !", CONFIG.TOAST_STYLES);

  }

  render() {
    // Get Authenticated user from AuthUserContext
    const authenticatedUser = this.context;
    //console.log(authenticatedUser);

    return (
      <div>
        <h1>Dashboard - @{authenticatedUser.username}</h1>
        <p>Coming soon...</p>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DashboardPage);