import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import * as CONFIG from '../../constants/config';


const config = {
  apiKey: "AIzaSyDdIS7AuF9n3Nt03wka_e59-qs9wAEvbXY",
  authDomain: "twtnmesearch.firebaseapp.com",
  databaseURL: "https://twtnmesearch.firebaseio.com",
  projectId: "twtnmesearch",
  storageBucket: "",
  messagingSenderId: "377933444527",
  appId: "1:377933444527:web:d9d5f5f417b93b9b"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();

    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  getAuthIdToken = () => this.auth.currentUser.getIdToken(true);

  getAuthUser = () => this.auth.currentUser;

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // Add isAdmin if user is a Twitonomy Admin (based on Twitter Id)
            let userIsAdmin = false;
            if (authUser.providerData && authUser.providerData[0] && CONFIG.ADMIN_USERS.includes(authUser.providerData[0].uid)) {
              userIsAdmin = true;
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              isAdmin: userIsAdmin,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** DB User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

}

export default Firebase;