import React, { Component } from 'react';
import { compose } from 'recompose';

import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-responsive-modal';

import { Bar } from 'react-chartjs-2';

import ReactJson from 'react-json-view';

import Tippy from '@tippy.js/react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import _ from 'lodash';

import { toast } from 'react-toastify';

import ReactTags from 'react-tag-autocomplete';

import { withFirebase } from '../Firebase';

import { withAuthorization } from '../Session';

import axios from 'axios';
import * as CONFIG from '../../constants/config';

import ls from 'local-storage';


let s3CacheJson = null;
let s3CacheKey = null;
let twitterSearchJson = null;

let INITIAL_STATE = {
  isLoadingS3CacheList: false,
  errorS3CacheList: null,
  S3CacheListData: null,
  S3CacheObjectKey: null,
  isLoadingS3CacheObject: false,
  errorS3CacheObject: null,
  S3CacheObjectLoaded: false,
  isLoadingSearch: false,
  errorSearch: null,
  searchFrom: new Date(),
  searchTo: new Date(),
  twitterSearchLoaded: false,
  openModal: false,
  chartData: {},
  tags: [],
  suggestionsQueryTags: [
    {id:1, needsAValue: true, name: "#"},
    {id:2, needsAValue: true, name: "@"},
    {id:3, needsAValue: true, name: "url:"},
    {id:4, needsAValue: true, name: "lang:"},
    {id:5, needsAValue: true, name: "retweets_of:"},
    {id:6, needsAValue: false, name: "is:retweet"},
    {id:7, needsAValue: false, name: "is:reply"},
    {id:8, needsAValue: false, name: "has:hashtags"},
    {id:9, needsAValue: false, name: "has:media"},
    {id:10, needsAValue: false, name: "has:mentions"},
    {id:11, needsAValue: false, name: "has:videos"},
    {id:12, needsAValue: false, name: "has:images"},
    {id:13, needsAValue: false, name: "has:links"},
    {id:14, needsAValue: false, name: "is:verified"},
    {id:15, needsAValue: true, name: "bounding_box:"},
    {id:16, needsAValue: true, name: "point_radius:"},
    {id:17, needsAValue: false, name: "has:geo"},
    {id:18, needsAValue: true, name: "place:"},
    {id:19, needsAValue: true, name: "place_country:"},
    {id:20, needsAValue: false, name: "has:profile_geo"},
    {id:21, needsAValue: true, name: "profile_country:"},
    {id:22, needsAValue: true, name: "profile_region:"},
    {id:23, needsAValue: true, name: "profile_locality:"},    
  ]
};

function S3CacheObject({ data }) {
  let dispJson = data ? (data.results ? data : { message: "No/invalid data" }) : { message: "Choose a file" };

  return (
    <ReactJson src={dispJson} name={false} theme="monokai" displayDataTypes={false} style={{height: "500px", "overflow": "scroll"}} />
  )
}

class Search30DaysPage extends Component {

  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.getTwitterSearchData = this.getTwitterSearchData.bind(this);
    this.getCacheObject = this.getCacheObject.bind(this);
    this.handleClickS3CacheList = this.handleClickS3CacheList.bind(this);
    this.clearS3CacheJson = this.clearS3CacheJson.bind(this);
    this.clearTwitterSearchJson = this.clearTwitterSearchJson.bind(this);

    this.handleQueryDeleteTag = this.handleQueryDeleteTag.bind(this);
    this.handleQueryAddTag = this.handleQueryAddTag.bind(this);


  }

  handleQueryDeleteTag(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({tags});
  }

  handleQueryAddTag(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({tags});
  }

  handleClickS3CacheList(e) {
    e.preventDefault();
    let object = e.target.getAttribute('href');
    //console.log("Clicked on " + object);
    this.getCacheObject(object);
  }

  clearS3CacheJson(e) {
    s3CacheJson = null;
    ls.remove('s3CacheJson');
    this.setState({
      S3CacheObjectKey: null,
      S3CacheObjectLoaded: false
    });
  }

  clearTwitterSearchJson(e) {
    twitterSearchJson = null;
    ls.remove('twitterSearchJson');
    this.setState({
      twitterSearchLoaded: false
    });
  }

  handleChangeSearchFrom = date => {
    this.setState({
      searchFrom: date
    });
  };

  handleChangeSearchTo = date => {
    this.setState({
      searchTo: date
    });
  };

  onOpenModal = () => {
    this.setState({ openModal: true });
  };
 
  onCloseModal = () => {
    this.setState({ openModal: false });
  };
  
  getS3CacheList() {
    this.setState({ isLoadingS3CacheList: true, errorS3CacheList: null, S3CacheListData: null });
    this.props.firebase.getAuthIdToken()
    .then(idToken => {
        // Firebase Auth Token retrieved
        //console.log('autht='+idToken);
        // Call the Twitonomy API
        const axiosHeaders = {
          'Authorization': 'Bearer '+idToken
        };
        const axiosCallParams = {
          act: 'list-all'
        };
        const axiosValidateStatus = function (status) {
          return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
        }
        axios.get(CONFIG.API_URL + '/searches', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            // API returned successful response
            let chartLabels = [];
            let chartValues = [];
            _.forEach(response.data.data, function(value, key) {
              chartLabels.push(value.month);
              chartValues.push(value.files.length);
            });
            this.setState({ 
              isLoadingS3CacheList: false,
              errorS3CacheList: null,
              S3CacheListData: response.data.data,
              chartData: {
                labels: _.reverse(chartLabels),
                datasets: [{
                  label: 'Files',
                  backgroundColor: 'rgba(255,99,132,0.2)',
                  borderColor: 'rgba(255,99,132,1)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                  hoverBorderColor: 'rgba(255,99,132,1)',
                  data: _.reverse(chartValues)
                }]
              }
            });            
          } else {
            // API returned an error
            let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
            this.setState({ isLoadingS3CacheList: false, errorS3CacheList: errorMessage, S3CacheListData: null });
          }          
        })
        .catch(error => {
          // Axios error
          console.log(JSON.stringify(error));
          let errorMessage = error.message ? error.message : 'An error occurred';
          this.setState({ isLoadingS3CacheList: false, errorS3CacheList: errorMessage, S3CacheListData: null });
        });
    })
    .catch(error => {
        // Error getting Firebase Auth Token
        console.log(error);
        this.setState({ isLoadingS3CacheList: false, errorS3CacheList: "Authentication error", S3CacheListData: null });
    });
  }

  getCacheObject(S3CacheObjectKey) {
    // we need to keep a reference of the toastId to be able to update it
    let toastId = null;
    this.setState({ S3CacheObjectKey: S3CacheObjectKey });
    console.log("getCacheObject: "+S3CacheObjectKey);
    this.setState({ isLoadingS3CacheObject: true, errorS3CacheObject: null, S3CacheListObject: null });
    this.props.firebase.getAuthIdToken()
    .then(idToken => {
        // Firebase Auth Token retrieved
        //console.log('autht='+idToken);
        // Call the Twitonomy API
        const axiosHeaders = {
          'Authorization': 'Bearer '+idToken
        };
        const axiosCallParams = {
          act: 'get',
          key: S3CacheObjectKey
        };
        const axiosValidateStatus = function (status) {
          return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
        }
        const axiosOnDownloadProgress = function (progressEvent) {
          // Do whatever you want with the native progress event
          const progress = progressEvent.loaded / progressEvent.total;
          // check if we already displayed a toast
          if(toastId === null){
              toastId = toast('Downloading '+S3CacheObjectKey+'...', {
                ...CONFIG.TOAST_STYLES,
                progress: progress
            });
          } else {
            toast.update(toastId, {
              ...CONFIG.TOAST_STYLES,
              progress: progress
            })
          }
        }
        axios.get(CONFIG.API_URL + '/searches', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus, onDownloadProgress: axiosOnDownloadProgress })
        .then(response => {
          //console.log(response);
          // Close toast
          toast.done(toast.id)
          if (response.status === 200) {
            // API returned successful response
            //console.log(response.data.data);

            // Update apiResponseJson variable
            s3CacheJson = response.data.data;

            // Save data in local storage
            ls.set('s3CacheJson', s3CacheJson);
            ls.set('s3CacheKey', S3CacheObjectKey);

            // Update state to trigger re-rendering
            this.setState({ 
              isLoadingS3CacheObject: false,
              errorS3CacheObject: null,
              S3CacheObjectLoaded: true 
            });
            
          } else {
            // API returned an error
            let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
            this.setState({ isLoadingS3CacheObject: false, errorS3CacheObject: errorMessage, S3CacheObjectLoaded: false });
          }          
        })
        .catch(error => {
          // Axios error
          console.log(JSON.stringify(error));
          // Close toast
          toast.done(toast.id)
          let errorMessage = error.message ? error.message : 'An error occurred';
          this.setState({ isLoadingS3CacheObject: false, errorS3CacheObject: errorMessage, S3CacheObjectLoaded: false });
        });
    })
    .catch(error => {
        // Error getting Firebase Auth Token
        console.log(error);
        this.setState({ isLoadingS3CacheObject: false, errorS3CacheObject: "Authentication error", S3CacheObjectLoaded: false });
    });
  }

  getTwitterSearchData() {
    const { tags, searchFrom, searchTo } = this.state;
    this.setState({ isLoadingSearch: true, errorSearch: null });
    this.props.firebase.getAuthIdToken()
    .then(idToken => {
        // Firebase Auth Token retrieved
        //console.log('autht='+idToken);
        // Convert search tags to search query
        console.log(JSON.stringify(tags));
        let searchQuery = '';
        _.forEach(tags, function(value) {
          searchQuery += value.name + (value.needsAValue ? '' : ' ');
        });
        // Convert searchFrom to Twitter format (timestamp in minute granularity, inclusive. Ex.: 201512220000)
        let searchFromTwitterFormat = moment(searchFrom).format("YYYYMMDD") + '0000';
        // Convert searchTo to Twitter format (timestamp in minute granularity, not inclusive, so must be 00min00sec of next day to include tweets posted at 23h59. Ex.: 201512230000)
        let searchToTwitterFormat = moment(searchTo).add(1, 'days').format("YYYYMMDD") + '0000';
        // Call the Twitonomy API
        const axiosHeaders = {
          'Authorization': 'Bearer '+idToken
        };
        const axiosCallParams = {
          query: searchQuery,
          from: searchFromTwitterFormat,
          to: searchToTwitterFormat
        };
        const axiosValidateStatus = function (status) {
          return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
        }
        console.log("Calling Twitonomy API /search-tweets: " + JSON.stringify(axiosCallParams));
        axios.get(CONFIG.API_URL + '/search-tweets', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            // API returned successful response

            // Update apiResponseJson variable
            twitterSearchJson = response.data.data;

            // Save data in local storage
            ls.set('twitterSearchJson', twitterSearchJson);

            // Update state to trigger re-rendering
            this.setState({ 
              isLoadingSearch: false,
              errorSearch: null,
              twitterSearchLoaded: true
            });
            // Reload S3 Cache list
            this.getS3CacheList();
          } else {
            // API returned an error
            let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
            this.setState({ isLoadingSearch: false, errorSearch: errorMessage, twitterSearchLoaded: false });
          }          
        })
        .catch(error => {
          // Axios error
          console.log(JSON.stringify(error));
          let errorMessage = error.message ? error.message : 'An error occurred';
          this.setState({ isLoadingSearch: false, errorSearch: errorMessage, twitterSearchLoaded: false });
        });
    })
    .catch(error => {
        // Error getting Firebase Auth Token
        console.log(error);
        this.setState({ isLoadingSearch: false, errorSearch: "Authentication error", twitterSearchLoaded: false });
    });
  }

  componentDidMount() {
    // const { searchQuery } = this.state;

    // Create Axios CancelToken to allow requests to be cancelled
    this.cancelTokenSource = axios.CancelToken.source();

    // Load S3 Cache list
    this.getS3CacheList();

    // Load s3CacheJson from local storage
    s3CacheKey = ls.get('s3CacheKey');
    s3CacheJson = ls.get('s3CacheJson');
    //console.log('s3CacheJson: ' + JSON.stringify(s3CacheJson));
    if(s3CacheJson) {
      this.setState({
        S3CacheObjectKey: s3CacheKey,
        S3CacheObjectLoaded: true
      });
    }

    // Load twitterSearchJson from local storage
    twitterSearchJson = ls.get('twitterSearchJson');
    //console.log('twitterSearchJson: ' + JSON.stringify(twitterSearchJson));
    if(twitterSearchJson) {
      this.setState({
        twitterSearchLoaded: true
      });
    }

  }

  componentWillUnmount() {
    // Cancel Axios requests
    this.cancelTokenSource.cancel('API calls cancelled');
  }

  render() {
    const { isLoadingSearch, errorSearch, tags, searchFrom, searchTo, openModal, chartData, isLoadingS3CacheList, errorS3CacheList, S3CacheListData, isLoadingS3CacheObject, errorS3CacheObject, S3CacheObjectKey } = this.state;
    const optionsChart = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
      legend: {
        display: false
      }
    };
    return (
        <div>
            <h1>Search 30-Days <Tippy content={<span>Using Twitter's Premium Search 30-day endpoint</span>} arrow={true}><span style={{cursor: 'help'}}><FontAwesomeIcon icon={faInfoCircle} size="sm" color="#AAAAAA" /></span></Tippy></h1>
            <h2>S3 Search Cache</h2>
            <Row>
              <Col xs={8}>
                {isLoadingS3CacheList ? <Spinner animation="grow" variant="info" size="sm" /> : (errorS3CacheList ? <Alert key='alert-error-cache' variant='danger' style={{ marginTop: '10px' }}>{errorS3CacheList}</Alert> : <S3CacheList data={S3CacheListData} onClick={this.handleClickS3CacheList} />)}
              </Col>
              <Col>
                <div>{Object.keys(chartData).length > 0 && <Bar data={chartData} options={optionsChart} />}</div>
              </Col>
            </Row>
            {S3CacheObjectKey && <Row style={{ marginTop: '10px', marginBottom: '5px' }}><Col><Badge variant="primary">{S3CacheObjectKey}</Badge></Col><Col><div style={{textAlign: 'right'}}><Button key="button-clear-s3cache" variant='secondary' size="sm" onClick={this.clearS3CacheJson}>Clear</Button></div></Col></Row>}
            {isLoadingS3CacheObject ? <Spinner animation="grow" variant="info" size="sm" /> : (errorS3CacheObject ? <Alert key='alert-error-object' variant='danger' style={{ marginTop: '10px' }}>{errorS3CacheObject}</Alert> : (s3CacheJson ? <S3CacheObject data={s3CacheJson} /> : ""))}
            <h2>New Search <span style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faQuestionCircle} onClick={this.onOpenModal} color="#AAAAAA" /></span></h2>
            
            <div style={{marginBottom: '.5rem'}}>Query</div>
            <ReactTags tags={tags} suggestions={this.state.suggestionsQueryTags} handleDelete={this.handleQueryDeleteTag} handleAddition={this.handleQueryAddTag} allowNew={true} placeholder={'Add a search parameter - [Enter] to confirm'} allowBackspace={false} addOnBlur={true} />
            <Row style={{marginTop: '1rem'}}>
              <Col md="auto">
                <div style={{marginBottom: '.5rem'}}>From</div>
                <div><DatePicker dateFormat="dd/MM/yyyy" selected={searchFrom} onChange={this.handleChangeSearchFrom} /></div>
              </Col>
              <Col md="auto">
                <div style={{marginBottom: '.5rem'}}>To</div>
                <div><DatePicker dateFormat="dd/MM/yyyy" selected={searchTo} onChange={this.handleChangeSearchTo} /></div>
              </Col>
              <Col xs lg="2">
                <div style={{marginBottom: '.5rem'}}>&nbsp;</div>
                <div style={{textAlign: 'right'}}>{isLoadingSearch && <Spinner animation="grow" variant="info" size="sm" style={{marginRight: '10px'}} />}<Button variant="primary" onClick={this.getTwitterSearchData} disabled={isLoadingSearch}>{isLoadingSearch ? 'Searching...' : 'Run Search'}</Button></div>
              </Col>
            </Row>            
            {errorSearch && <Alert show={errorSearch ? true : false} key="alert-error-api" variant="danger" style={{ marginTop: '10px' }}>{errorSearch}</Alert>}
            {twitterSearchJson && <div style={{marginTop: '10px'}}><div style={{textAlign: 'right'}}><Button key="button-clear-twittersearch" variant='secondary' size="sm" style={{marginBottom: '5px'}} onClick={this.clearTwitterSearchJson}>Clear</Button></div><ReactJson src={twitterSearchJson} name={false} theme="monokai" displayDataTypes={false} style={{height: "500px", "overflow": "scroll"}} /></div>}
            <Modal open={openModal} onClose={this.onCloseModal} center>
              <h2>Search Query</h2>
              <p>Supports <a href="https://developer.twitter.com/en/docs/tweets/search/overview/premium#AvailableOperators" target="_blank" rel="noopener noreferrer">premium search operators</a>:</p>
              <Row>
                <Col>
                  <ul>
                    <li>keyword</li>
                    <li>"quoted phrase"</li>
                    <li>#</li>
                    <li>@</li>
                    <li>url:</li>
                    <li>lang:</li>
                    <li>retweets_of:</li>
                    <li>is:reply</li>
                    <li>is:retweet</li>
                  </ul>                
                </Col>
                <Col>
                  <ul>
                    <li>has:mentions</li>
                    <li>has:hashtags</li>
                    <li>has:media</li>
                    <li>has:videos</li>
                    <li>has:images</li>
                    <li>has:links</li>
                    <li>is:verified</li>
                    <li>bounding_box:[west_long south_lat east_long north_lat]</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>point_radius:[lon lat radius]</li>
                    <li>has:geo</li>
                    <li>place:</li>
                    <li>place_country:</li>
                    <li>has:profile_geo</li>
                    <li>profile_country:</li>
                    <li>profile_region:</li>
                    <li>profile_locality:</li>
                  </ul>
                </Col>
              </Row>
              <h2>From</h2>
              <p>Timestamp in minute granularity, inclusive (i.e. 12:00 includes the 00 minute)</p>
              <h2>To</h2>
              <p>Timestamp in minute granularity, NOT inclusive (i.e. 11:59 does not include the 59th minute of the hour)</p>
            </Modal>
        </div>        
    );
  }
}


const S3CacheList = ({ data, onClick }) => (
  <div style={{height: "185px", "overflow": "scroll"}}>
    {data && data.length > 0 ? 
      <Table striped bordered hover size="sm">
        <tbody>
          { 
            data.map((objectMonth) => (
              objectMonth.files.map((file, indexFile) => (
                <tr key={'file-'+indexFile}>
                  <td>{objectMonth.month}</td>
                  <td><a href={file.name} onClick={onClick}>{file.name}</a></td>
                  <td>{file.timestamp}</td>
                  <td>{file.size}</td>
                </tr>
              ))
            )) 
          }
        </tbody>
      </Table>
      :
      <strong>No data</strong>
    }
  </div>
);

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase,
  )(Search30DaysPage);