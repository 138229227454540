import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const HomePage = () => (
  <div>
    <h1 style={{ textAlign: "center", marginTop: 50 }}>Welcome to twtn.me</h1>
    <div style={{ textAlign: "center", marginTop: 20 }}><Link to={ROUTES.SIGN_IN}>Get started <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" /></Link></div>
  </div>
);

export default HomePage;