import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { Alert, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import axios from 'axios';
import * as CONFIG from '../../constants/config';

const SignInPage = () => (
  <div className="textBlue" style={{ textAlign: "center", marginTop: 100 }}>
    <p>Sign in with Twitter to get started</p>
    <SignInTwitter />
  </div>
);

class SignInWithTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      error: null,
      signingInProgress: false
    };
  }

  onSubmit = event => {
    console.log("[FirebaseAuth] Signing in...");
    this.setState({signingInProgress: true});
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        console.log("[FirebaseAuth] Signed in to Firebase (" + socialAuthUser.user.uid + ") using Twitter Sign-in as @" + socialAuthUser.additionalUserInfo.username);   
        //console.log("[FirebaseAuth] socialAuthUser: " + JSON.stringify(socialAuthUser));             
        console.log('[FirebaseAuth] Getting Id Token...');
        let thisFirebase = this.props.firebase;
        return new Promise(function(resolve, reject) {
          thisFirebase.getAuthIdToken().then(idToken => {
            // Firebase Auth Token retrieved
            console.log('[FirebaseAuth] Id Token: '+idToken);
            resolve({
              idToken: idToken,
              socialAuthUser: socialAuthUser
            });
          })
        });
      })
      .then(userAndToken => {
        console.log("[FirebaseAuth] Signing in with backend API...");
        let socialAuthUser = userAndToken.socialAuthUser;
        let idToken = userAndToken.idToken;        
        
        return new Promise(function(resolve, reject) {
          const axiosCallParams = {
            autht: idToken,
            tuid: socialAuthUser.user.providerData[0].uid,
            tsn: socialAuthUser.additionalUserInfo.username,
            tot: socialAuthUser.credential.accessToken,
            tos: socialAuthUser.credential.secret
          };
          console.log("API call params: " + JSON.stringify(axiosCallParams));
          const axiosValidateStatus = function (status) {
            return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
          }
          axios.get(CONFIG.API_URL + '/signin', { params: axiosCallParams, validateStatus: axiosValidateStatus })
          .then(response => {
            console.log(response);
            if (response.status === 200) {
              // API returned successful response
              console.log("[FirebaseAuth] Signed in successfuly with backend API");
              console.log("API response: " + JSON.stringify(response));
              resolve();
            } else {
              // API returned an error
              let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
              console.log("[FirebaseAuth] Failed signing in with backend API");
              console.log(errorMessage);
              reject(errorMessage);
            }          
          })
          .catch(error => {
            // Axios error
            console.log(JSON.stringify(error));
            let errorMessage = error.message ? error.message : 'An error occurred';
            reject(errorMessage);
          });
        });        
      })
      .then(() => {
        console.log("[FirebaseAuth] Sign-in completed, redirecting to Dashboard...");
        this.setState({ error: null, signingInProgress: false });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        console.log("[FirebaseAuth] Sign-in failed!");
        this.props.firebase.doSignOut(); // Log out from Firebase to unsure user is not partially authenticated
        this.setState({ error: error, signingInProgress: false });
      });
    event.preventDefault();  
  }

  render() {
    const { error, signingInProgress } = this.state;
    // const error = { message: "Dummy error message" };

    return (
      <form onSubmit={this.onSubmit}>
        <Button type="submit" disabled={signingInProgress}><FontAwesomeIcon icon={faTwitter} size="lg" style={{marginRight: "8px"}} />{signingInProgress ? "Signing in..." : "Sign in with Twitter"}</Button>
        {error && <Alert variant="danger" style={{ marginTop: 25 }}><Alert.Heading style={{ fontSize: "1.2rem"}}>An error occured, please try again...</Alert.Heading><span>{error.message}</span></Alert>}
      </form>
    );
  }
}

const SignInTwitter = compose(
  withRouter,
  withFirebase
)(SignInWithTwitterBase);

export default SignInPage;

export { SignInTwitter };