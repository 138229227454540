import React from 'react';
import { Container, Row, Col, Navbar, NavbarBrand } from 'react-bootstrap';

import  { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import logo from '../../logo.jpg'
import defaultProfileImg from '../../default_profile.png';

import { AuthUserContext } from '../Session';

import Navigation from '../Navigation';

const Header = () => (
  <header>
    <Navbar fixed="top" color="light" light="true" expand="xs" className="border-bottom border-gray bg-white" style={{ height: 60 }}>
    
      <Container style={{ paddingLeft: 0 }}>
        <Row noGutters className="position-relative w-100 align-items-center">
        
          <Col className="d-lg-flex justify-content-start" sm={10}>

            <NavbarBrand className="d-inline-block p-0" style={{ width: 130 }}>
              <AuthUserContext.Consumer>
                {authUser => 
                    authUser ? <Link to={ROUTES.DASHBOARD}><img src={logo} alt="logo" className="position-relative img-fluid" /></Link> : <Link to={ROUTES.HOME}><img src={logo} alt="logo" className="position-relative img-fluid" /></Link>
                }
              </AuthUserContext.Consumer>
            </NavbarBrand>
            <div style={{ marginTop: 10, marginLeft: 20 }}><Navigation /></div>
            
          </Col>
          
          <Col className="d-none d-lg-flex justify-content-end" sm={2}>
            <AuthUserContext.Consumer>
              {authUser => 
                  authUser ? <Link to={ROUTES.ACCOUNT}><img src={authUser.profileImage} alt="avatar" className="img-fluid rounded-circle" style={{ width: 36 }} /></Link> : <Link to={ROUTES.SIGN_IN}><img src={defaultProfileImg} alt="avatar" className="img-fluid rounded-circle" style={{ width: 36 }} /></Link>
              }
            </AuthUserContext.Consumer>
          </Col>
          
        </Row>
      </Container>
      
    </Navbar>
  </header>
);

export default Header;