import React, { Component } from 'react';
import { compose } from 'recompose';

import _ from 'lodash';

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import ReactJson from 'react-json-view';

import { withFirebase } from '../Firebase';

import { withAuthorization } from '../Session';

import axios from 'axios';
import * as CONFIG from '../../constants/config';

import ls from 'local-storage';


let apiResponseJson = null;

let INITIAL_STATE = {
  endpoint: null,
  params: null,
  isLoading: false,
  errorLoading: null,
  apiResponseLoaded: false,
};

class LabsPage extends Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        this.handleUsersClick = this.handleUsersClick.bind(this);
        this.handleTweetsClick = this.handleTweetsClick.bind(this);
        this.handleTweetsMetricsClick = this.handleTweetsMetricsClick.bind(this);

    }

    callApi(endpoint, params) {
      console.log("Calling " + _.upperFirst(endpoint) + " endpoint: "+JSON.stringify(params));
      this.setState({ isLoading: true, errorLoading: null, apiResponseLoaded: false });
      this.props.firebase.getAuthIdToken()
      .then(idToken => {
          // Firebase Auth Token retrieved
          //console.log('autht='+idToken);
          const axiosHeaders = {
            'Authorization': 'Bearer '+idToken
          };
          const axiosCallParams = {
            act: 'lab',
            type: endpoint
          };
          if(endpoint === 'users') {
            axiosCallParams.sn = params.sn;
          }
          if(endpoint === 'tweets' || endpoint === 'tweets-metrics') {
            axiosCallParams.id = params.id;
          }
          const axiosValidateStatus = function (status) {
            return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
          }
          axios.get(CONFIG.API_URL + '/tests', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus })
          .then(response => {
            //console.log(response);
            if (response.status === 200) {
              // API returned successful response
              //console.log(response.data.data);

              // Update apiResponseJson variable
              apiResponseJson = response.data.data;

              // Save data in local storage
              ls.set('apiResponseJson', apiResponseJson);

              // Update state to trigger re-rendering
              this.setState({ 
                isLoading: false,
                errorLoading: null,
                apiResponseLoaded: true
              });
              
            } else {
              // API returned an error
              let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
              this.setState({ isLoading: false, errorLoading: errorMessage, apiResponseLoaded: false });
            }          
          })
          .catch(error => {
            // Axios error
            console.log(JSON.stringify(error));
            let errorMessage = error.message ? error.message : 'An error occurred';
            this.setState({ isLoading: false, errorLoading: errorMessage, apiResponseLoaded: false });
          });
      })
      .catch(error => {
          // Error getting Firebase Auth Token
          console.log(error);
          this.setState({ isLoading: false, errorLoading: "Authentication error", apiResponseLoaded: false });
      });
    }

    handleUsersClick() {
      this.setState({
        endpoint: 'users',
        params: {
          sn: 'TwitterAPI'
        }        
      }, () => {
        // Run once the state has been updated
        const { params } = this.state;
        this.callApi('users', params);
      });
    }

    handleTweetsClick() {
      this.setState({
        endpoint: 'tweets',
        params: {
          id: '1067094924124872705'
        }        
      }, () => {
        // Run once the state has been updated
        const { params } = this.state;
        this.callApi('tweets', params);
      });
    }

    handleTweetsMetricsClick() {
      this.setState({
        endpoint: 'tweets-metrics',
        params: {
          id: '1167249478937538560'
        }        
      }, () => {
        // Run once the state has been updated
        const { params } = this.state;
        this.callApi('tweets-metrics', params);
      });
    }

    componentDidMount() {
        //const { params } = this.state;
    
        // Create Axios CancelToken to allow requests to be cancelled
        this.cancelTokenSource = axios.CancelToken.source();

        // Load apiResponse from local storage
        apiResponseJson = ls.get('apiResponseJson');
        //console.log('apiResponseJson: ' + JSON.stringify(apiResponseJson));
        if(apiResponseJson) {
          let apiResponseJsonEndpoint = null;
          if(apiResponseJson.endpoint && apiResponseJson.endpoint === 'https://api.twitter.com/labs/1/users') {
            apiResponseJsonEndpoint = 'users'
          } else if(apiResponseJson.endpoint && apiResponseJson.endpoint === 'https://api.twitter.com/labs/1/tweets') {
            apiResponseJsonEndpoint = 'tweets'
          } else if(apiResponseJson.endpoint && apiResponseJson.endpoint === 'https://api.twitter.com/labs/1/tweets/metrics/private') {
            apiResponseJsonEndpoint = 'tweets-metrics'
          }
          this.setState({
            endpoint: apiResponseJsonEndpoint,
            apiResponseLoaded: true
          });
        }
    
    }

    componentWillUnmount() {
        // Cancel Axios requests
        this.cancelTokenSource.cancel('API calls cancelled');
    }

    render() {
        const { endpoint, isLoading, errorLoading } = this.state;

        return (
            <div>
                <h1>Twitter Labs</h1>
                <h2>Endpoint</h2>
                <Button key="button-endpoint-users" variant={endpoint === "users" ? "primary" : "secondary"} disabled={isLoading} onClick={this.handleUsersClick}>Users</Button> <Button key="button-endpoint-tweets" variant={endpoint === "tweets" ? "primary" : "secondary"} disabled={isLoading} onClick={this.handleTweetsClick}>Tweets</Button> <Button key="button-endpoint-tweets-metrics" variant={endpoint === "tweets-metrics" ? "primary" : "secondary"} disabled={isLoading} onClick={this.handleTweetsMetricsClick}>Tweets Metrics</Button>
                <h2 style={{ marginTop: '20px' }}>Request</h2>
                {isLoading ? <Spinner animation="grow" variant="info" size="sm" /> : (apiResponseJson ? <div style={{ marginTop: '10px' }}><Badge variant="primary">{apiResponseJson.endpoint}</Badge><ReactJson src={apiResponseJson.params} name={false} displayDataTypes={false} /></div> : <i>Select an endpoint...</i>)}
                <h2 style={{ marginTop: '20px' }}>Response</h2>
                {isLoading ? <Spinner animation="grow" variant="info" size="sm" /> : (errorLoading ? <Alert show={errorLoading ? true : false} key="alert-error-api" variant="danger" style={{ marginTop: '10px' }}>{errorLoading}</Alert> : (apiResponseJson ? <ReactJson src={apiResponseJson.results} name={false} theme="monokai" displayDataTypes={false} style={{height: "500px", "overflow": "scroll"}} /> : <i>Select an endpoint...</i>))}   
            </div>
        );
    }

}   

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase,
  )(LabsPage);
