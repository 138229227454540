import React, { Component } from 'react';
import { compose } from 'recompose';

import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

import Moment from 'react-moment';

import { withFirebase } from '../Firebase';

import SignOutButton from '../SignOut';

import { AuthUserContext, withAuthorization } from '../Session';

import defaultProfileImg from '../../default_profile.png';

import axios from 'axios';
import * as CONFIG from '../../constants/config';


let INITIAL_STATE = {
  authorizationToken: null,
  showAuthorizationToken: false,
  isLoading: false,
  error: null,
  twitterData: {
    created_at: null,
    description: null,
    id: null,
    location: null,
    name: null,
    screen_name: null,
    url: null
  }
};

class AccountPage extends Component {

  static contextType = AuthUserContext;

  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.setTwitterUserData = this.setTwitterUserData.bind(this);
    this.showAuthorizationToken = this.showAuthorizationToken.bind(this);

  }

  setTwitterUserData(data) {
    // console.log(data);
    this.setState({ 
      isLoading: false,
      twitterData: {
        created_at: data.created_at,
        description: data.description,
        id: data.id,
        location: data.location,
        name: data.name,
        screen_name: data.screen_name,
        url: data.url,
        profile_image_url_https: data.profile_image_url_https
      } 
    });
  }

  showAuthorizationToken() {
    this.setState({
      showAuthorizationToken: true
    });
  }

  componentDidMount() {
    // const { searchTerm } = this.state;    

    // Get Authenticated user from AuthUserContext
    const authenticatedUser = this.context;
    //console.log(authenticatedUser);

    // Create Axios CancelToken to allow requests to be cancelled
    this.cancelTokenSource = axios.CancelToken.source();

    this.setState({ isLoading: true, error: null });
    if (this.props.firebase.getAuthUser()) {
      this.props.firebase.getAuthIdToken()
      .then(idToken => {
        // Firebase Auth Token retrieved
        //console.log('autht='+idToken);
        this.setState({
          authorizationToken: idToken
        });
        // Call the Twitonomy API
        const axiosHeaders = {
          'Authorization': 'Bearer '+idToken
        };
        const axiosCallParams = {
          twtokk: authenticatedUser.authToken,
          twtoks: authenticatedUser.authTokenSecret,
        };
        const axiosValidateStatus = function (status) {
          return status < 500; // Instruct Axios to reject only if the status code is greater than or equal to 500 (to allow display of error messages returned by the API)
        }
        axios.get(CONFIG.API_URL + '/account', { headers: axiosHeaders, params: axiosCallParams, cancelToken: this.cancelTokenSource.token, validateStatus: axiosValidateStatus })
        .then(response => {
          //console.log(response);
          if (response.status === 200 && response.data.data.twitterUser) {
            // API returned successful response
            this.setTwitterUserData(response.data.data.twitterUser);
          } else {
            // API returned an error
            let errorMessage = (response.data && response.data.error && response.data.error.message) ? response.data.error.message : 'Error loading data ['+response.status+']';
            this.setState({ isLoading: false, error: errorMessage });
          }          
        })
        .catch(error => {
          // Axios error
          console.log(JSON.stringify(error));
          let errorMessage = error.message ? error.message : 'An error occurred';
          this.setState({ isLoading: false, error: errorMessage });
        });
      })
      .catch(error => {
        // Error getting Firebase Auth Token
        console.log(error);
        this.setState({ isLoading: false, error: "Authentication error" });
      });
    } else {
      this.setState({ isLoading: false, error: "No authenticated user" });
    }   

  }

  componentWillUnmount() {
    // Cancel Axios requests
    this.cancelTokenSource.cancel('API calls cancelled');
  }

  render() {
    const { authorizationToken, showAuthorizationToken, isLoading, error, twitterData } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => { 
            // console.log("authUser: " + JSON.stringify(authUser));
            return (
              <div>
                <h1>Account</h1>
                <Row>
                  <Col>
                    <h2 style={{marginTop: 0}}>Twtn.me account</h2>
                    <ListGroup>
                      <ListGroup.Item><strong>User Id:</strong> {authUser.uid}</ListGroup.Item>
                      <ListGroup.Item><strong>Username:</strong> {authUser.username}</ListGroup.Item>
                      <ListGroup.Item><strong>Twitter Id:</strong> {authUser.userId}</ListGroup.Item>
                      { showAuthorizationToken && <ListGroup.Item><code style={{fontSize: '0.7em', lineHeight: '0.5em'}}>{authorizationToken}</code></ListGroup.Item> }
                      <ListGroup.Item><Row><Col><SignOutButton /></Col><Col><div style={{textAlign: 'right'}}><Button variant="outline-secondary" size="sm" onClick={this.showAuthorizationToken}><FontAwesomeIcon icon={faUserShield} /> Show AuthToken</Button></div></Col></Row></ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col>
                    <h2 style={{marginTop: 0}}>Twitter account</h2>
                    <Alert show={error ? true : false} key={"alert-error-api"} variant={"danger"} style={{ width: '50%' }}>{error}</Alert>
                    <ListGroup>
                      <ListGroup.Item variant="info"><img src={!isLoading ? twitterData.profile_image_url_https : defaultProfileImg} alt="avatar" className="rounded-circle" style={{width: 30, marginRight: 10}} /><strong>@{authUser.username}</strong></ListGroup.Item>
                      <ListGroup.Item><strong>Name:</strong> {!isLoading ? twitterData.name : <Spinner animation="grow" variant="info" size="sm" />}</ListGroup.Item>
                      <ListGroup.Item><strong>Description:</strong> {!isLoading ? twitterData.description : <Spinner animation="grow" variant="info" size="sm" />}</ListGroup.Item>
                      <ListGroup.Item><strong>URL:</strong> {!isLoading ? <a href={twitterData.url} target="_blank" rel="noopener noreferrer">{twitterData.url}</a> : <Spinner animation="grow" variant="info" size="sm" />}</ListGroup.Item>
                      <ListGroup.Item><strong>Location:</strong> {!isLoading ? twitterData.location : <Spinner animation="grow" variant="info" size="sm" />}</ListGroup.Item>
                      <ListGroup.Item><strong>Created:</strong> {!isLoading ? (twitterData.created_at ? <Moment parse="ddd MMM D HH:mm:ss ZZ YYYY" format="DD MMMM YYYY">{twitterData.created_at}</Moment> : "") : <Spinner animation="grow" variant="info" size="sm" />}</ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            );
        }}
      </AuthUserContext.Consumer>  
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase,
  )(AccountPage);