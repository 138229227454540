import React, { Fragment, Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Header from '../Header';

import HomePage from '../Home';
import SignInPage from '../SignIn';
import DashboardPage from '../Dashboard';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Search30DaysPage from '../Search30Days';
import LabsPage from '../Labs';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

class App extends Component {
  render(){
    return (
      <Router>
        <Fragment>
          <Header />
          <main className="my-3 py-3">
            <Container className="px-0">
              <Row className="pt-md-5 w-100 position-relative">          
                <Col style={{ paddingRight: 0 }}>
                  <Route exact path={ROUTES.HOME} component={HomePage} />
                  <Route exact path={ROUTES.ROOT_INDEX} component={DashboardPage}/>
                  <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                  <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
                  <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                  <Route path={ROUTES.ADMIN} component={AdminPage} />
                  <Route path={ROUTES.SEARCH_30_DAYS} component={Search30DaysPage} />
                  <Route path={ROUTES.LABS} component={LabsPage} />
                </Col>            
              </Row>
            </Container>
          </main>
          <ToastContainer transition={Slide} />
        </Fragment>
      </Router>
    );
  }
}


export default withAuthentication(App);